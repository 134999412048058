import React, { useMemo, useState, useEffect, useCallback, useReducer } from 'react';

import {
	useAjax,
	XhrStatus,
	TranslatedString
} from '@bosch/bt-ui';

import './HomePage.css';
import withErrorHandler from '../components/ErrorHandler';
import { Tabs, Tab, Container } from "react-bootstrap";
import AddNewPage from './AddNewPage/AddNewPage';
import ManageExistingPage from './ManageExistingPage/ManageExistingPage';

const HomePage = (props) => {
	const {
		token
	} = props

	return (
		<div id="home-page">
			<h2 className="font-large">
			</h2>
			{
				token.token ?
					<Container className="mt-3">
						<Tabs defaultActiveKey="add-new" id="uncontrolled-tab-example" className="custom-tabs" fill>
							<Tab eventKey="add-new" title="Add New">
								<AddNewPage token={token.token} />
							</Tab>
							<Tab eventKey="manage-existing" title="Manage Existing">
								<ManageExistingPage token={token.token} />
							</Tab>
						</Tabs>
					</Container>
					:
					<h1 className="font-large">
						<TranslatedString stringId="unauthorized-description" />
					</h1>
			}
		</div>
	);
};

export default withErrorHandler(React.memo(HomePage));