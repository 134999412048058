import React, { useMemo, useState, useEffect } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    flexRender,
} from '@tanstack/react-table';
import { Table, Container, Row, Col, Button } from 'react-bootstrap';
import TableFilter from '../../../components/TableFilter';
import ConfirmationAlert from '../../../components/ConfirmationAlert';
import ProcessDialog from '../dialogs/ProcessDialog';

const PanelsListByUser = ({ user }) => {
    const initialData = useMemo(
        () => [
            { id: '1', name: 'Panel 1', location: 'Store A' },
            { id: '2', name: 'Panel 2', location: 'Store B' },
            { id: '3', name: 'Panel 3', location: 'Walgreens #1234' },
            { id: '4', name: 'Panel 4', location: 'Walmart #45334' },
            { id: '5', name: 'Panel ACC', location: 'POI' },
        ],
        []
    );

    const [data, setData] = useState(initialData);
    const [globalFilter, setGlobalFilter] = useState('');
    const [rowSelection, setRowSelection] = useState({});
    const [showConfirm, setShowConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'location',
                header: 'Location',
            },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter,
            rowSelection,
        },
        onGlobalFilterChange: setGlobalFilter,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: 'auto',
        enableRowSelection: true,
        getRowId: (row) => row.id,
    });

    const handleDelete = () => {
        setShowConfirm(true);
    };

    const confirmDelete = () => {
        const selectedRowIds = Object.keys(rowSelection);
        const newData = data.filter((row) => !selectedRowIds.includes(row.id));
        setData(newData);
        setRowSelection({});
        setShowConfirm(false);
    };

    const cancelDelete = () => {
        setShowConfirm(false);
    };

    const handleSelectAll = () => {
        const allRowIds = table.getRowModel().rows.map((row) => row.id);
        const isAllSelected = allRowIds.every((id) => rowSelection[id]);
        if (isAllSelected) {
            setRowSelection({});
        } else {
            const newSelection = {};
            allRowIds.forEach((id) => {
                newSelection[id] = true;
            });
            setRowSelection(newSelection);
        }
    };

    const isAllSelected = useMemo(() => {
        const allRowIds = table.getRowModel().rows.map((row) => row.id);
        return allRowIds.length > 0 && allRowIds.every((id) => rowSelection[id]);
    }, [rowSelection, table]);

    const handleOpenDialog = () => setShowDialog(true);
    const handleCloseDialog = () => setShowDialog(false);

    return (
        <Container>
            <Row className="mt-4">
                <Col>

                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <Button
                            variant="primary"
                            onClick={handleSelectAll}
                            className="me-2"
                            disabled={data.length === 0}
                        >
                            {isAllSelected ? 'Deselect All' : 'Select All'}
                        </Button>
                        <Button
                            variant="danger"
                            onClick={handleDelete}
                            disabled={Object.keys(rowSelection).length === 0}
                        >
                            Remove Selected Panels
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleOpenDialog}
                            className="ms-auto"
                        >
                            Show Process
                        </Button>
                    </div>

                    <div className="d-flex align-items-center w-100">
                        <div className="flex-grow-1">
                            <TableFilter filterValue={globalFilter} setFilter={setGlobalFilter} />
                        </div>
                    </div>

                    <Table bordered hover variant="info" className="mt-2">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    key={row.id}
                                    onClick={() => row.toggleSelected()}
                                    style={{
                                        backgroundColor: row.getIsSelected() ? 'lightblue' : 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            key={cell.id}
                                            style={{ backgroundColor: 'inherit', cursor: 'pointer' }}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <ConfirmationAlert
                        text="Are you sure you want to delete the selected panels? This action cannot be undone."
                        onConfirm={confirmDelete}
                        onCancel={cancelDelete}
                        show={showConfirm}
                        onHide={() => setShowConfirm(false)}
                    />

                    <ProcessDialog
                        show={showDialog}
                        onHide={handleCloseDialog}
                        infoMessage="Here is some important information."
                        mode="panels"
                    />

                </Col>
            </Row>
        </Container>
    );
};

export default PanelsListByUser;
