// UsersList.jsx

import React, { useMemo, useState, useEffect } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    flexRender,
} from '@tanstack/react-table';
import { Table, Container, Row, Col } from 'react-bootstrap';
import TableFilter from '../../../components/TableFilter';

const UsersList = ({ onUserSelect }) => {
    const columns = useMemo(
        () => [
            {
                accessorKey: 'firstName',
                header: 'First Name',
            },
            {
                accessorKey: 'lastName',
                header: 'Last Name',
            },
            {
                accessorKey: 'email',
                header: 'Email',
            },
        ],
        []
    );

    const data = useMemo(
        () => [
            {
                id: '1',
                firstName: 'Issac',
                lastName: 'Liao',
                email: 'issac@yahoo.com',
            },
            {
                id: '2',
                firstName: 'Yu-Jui',
                lastName: 'Chang',
                email: 'abc@test.com',
            },
            {
                id: '3',
                firstName: 'Tester',
                lastName: 'Chen',
                email: 'test@ttt.com',
            },
        ],
        []
    );

    const [globalFilter, setGlobalFilter] = useState('');
    const [rowSelection, setRowSelection] = useState({});

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter,
            rowSelection,
        },
        onGlobalFilterChange: setGlobalFilter,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: 'auto',
        enableRowSelection: true,
        enableMultiRowSelection: false,
        getRowId: (row) => row.id,
    });

    useEffect(() => {
        const selectedRows = table.getSelectedRowModel().rows;
        if (selectedRows.length > 0) {
            onUserSelect(selectedRows[0].original);
        } else {
            onUserSelect(null);
        }
    }, [rowSelection, onUserSelect, table]);

    return (
        <Container>
            <Row className="mt-4">
                <Col>
                    <div className="mb-2">
                        <TableFilter filterValue={globalFilter} setFilter={setGlobalFilter} />
                    </div>

                    <Table bordered hover variant="info">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    key={row.id}
                                    onClick={() => row.toggleSelected()}
                                    style={{
                                        backgroundColor: row.getIsSelected() ? 'lightblue' : 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} style={{ backgroundColor: 'inherit', cursor: 'pointer' }}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default UsersList;
