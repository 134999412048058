import React, { useMemo, useState, useEffect, useCallback, useReducer } from 'react';

import {
	Footer,
	TranslatedString
} from '@bosch/bt-ui';

//import PrivacySettingsDialog from './PrivacySettingsDialog';
import withErrorHandler from './ErrorHandler';

const MainFooter = (props) => {
	const shouldShowVersionInFooter = false;

	const footerCopyrightSection = useMemo(
		() => {
			let myversion = null;

			if (shouldShowVersionInFooter) {
				myversion = { label: <TranslatedString stringId="Version" />, text: 'insert app version here' };
			}

			return {
				copyrightsText: '© 2021 Robert Bosch GmbH',
				version: myversion
			};
		},
		[shouldShowVersionInFooter]
	);

	return (
		<Footer
			footerCopyright={footerCopyrightSection}
		/>
	);
	{
		//t.state.shouldShowPrivacySettings &&
		//<PrivacySettingsDialog
		//    hideDialog={t.hidePrivacySettings}
		///>
	}
};

export default withErrorHandler(React.memo(MainFooter));