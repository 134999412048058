import React, { useMemo, useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, getFilteredRowModel, flexRender } from '@tanstack/react-table';
import { Table, Container, Row, Col, Button } from 'react-bootstrap';
import TableFilter from '../../../components/TableFilter';
import { useAddNewStore } from '../../../store/add_new_store';
import mockdata from '../../../../mockdata/panelsList.json';

const PanelsList = () => {
    const columns = useMemo(() => [
        { accessorKey: 'name', header: 'Name' },
        { accessorKey: 'location', header: 'Location' },
    ], []);

    const data = useMemo(() => mockdata.data, []);

    const [globalFilter, setGlobalFilter] = React.useState('');
    const [rowSelection, setRowSelection] = useState({});

    const {
        selectedPanels,
        setSelectedPanels,
        selectedPanelsRow,
        setSelectedPanelsRow,
    } = useAddNewStore();

    const table = useReactTable({
        data,
        columns,
        state: { globalFilter, rowSelection },
        onGlobalFilterChange: setGlobalFilter,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: 'auto',
        enableRowSelection: true,
        enableMultiRowSelection: true,
        getRowId: (row) => row.id,
    });

    useEffect(() => {
        setSelectedPanelsRow(rowSelection);
    }, [rowSelection, setSelectedPanelsRow]);

    useEffect(() => {
        setRowSelection(selectedPanelsRow);
    }, [selectedPanelsRow]);

    useEffect(() => {
        const selectedRows = table.getSelectedRowModel().rows.map(row => row.original);
        setSelectedPanels(selectedRows);
    }, [rowSelection, setSelectedPanels, table]);

    const handleSelectAll = () => {
        if (Object.keys(rowSelection).length === data.length) {
            setRowSelection({});
        } else {
            const allRowIds = table.getRowModel().rows.reduce((acc, row) => {
                acc[row.id] = true;
                return acc;
            }, {});
            setRowSelection(allRowIds);
        }
    };

    return (
        <Container>
            <Row className="mt-4">
                <Col>
                    <h4>Panels</h4>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col className="d-flex">
                    <div className="flex-grow-1 me-3">
                        <TableFilter filterValue={globalFilter} setFilter={setGlobalFilter} />
                    </div>
                    <Button variant="primary" onClick={handleSelectAll}>
                        {Object.keys(rowSelection).length === data.length ? 'Deselect All' : 'Select All'}
                    </Button>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col>
                    <Table bordered hover variant="info">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th key={header.id}>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr
                                    key={row.id}
                                    onClick={() => row.toggleSelected()}
                                    style={{
                                        backgroundColor: row.getIsSelected() ? 'lightblue' : 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id} style={{ backgroundColor: 'inherit', cursor: 'pointer' }}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default PanelsList;
