import React, { useMemo, useState, useEffect } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    flexRender,
} from '@tanstack/react-table';
import { Table, Container, Row, Col } from 'react-bootstrap';
import TableFilter from '../../../components/TableFilter';
import { useQuery } from '@tanstack/react-query';
import CenterLoadingContainer from '../../../components/CenterLoadingContainer';

const fetchCompanyData = async (token) => {
    const response = await fetch(MOBY_API_URL + '/api/companymanager/v1/companies/user1', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

const fetchPanelsList = async (token, companyId) => {
    const response = await fetch(MOBY_API_URL + '/api/devicemanager/v1/panels/' + companyId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

const PanelsList = ({ onPanelSelect, token }) => {
    const columns = useMemo(
        () => [
            {
                accessorKey: 'panelName',
                header: 'Panel Name',
            },
            {
                accessorKey: 'deviceId',
                header: 'Device ID',
            },
            {
                accessorKey: 'cloudId',
                header: 'Cloud ID',
            },
        ],
        []
    );

    // Fetch company data
    const { data: companyData } = useQuery({
        queryKey: ['companyData'],
        queryFn: () => fetchCompanyData(token),
    });

    // Memoized companyId extraction
    const companyId = useMemo(() => {
        if (companyData?.data?.length > 0) {
            return companyData.data[0]?.companyId || null;
        }
        return null;
    }, [companyData]);

    // Conditionally fetch panelsList only if companyId is available
    const { data: panelsList, isLoading } = useQuery({
        queryKey: ['panelsList', companyId],
        queryFn: () => fetchPanelsList(token, companyId),
        enabled: !!companyId,  // Only run this query when companyId is not null
    });

    const [globalFilter, setGlobalFilter] = useState('');
    const [rowSelection, setRowSelection] = useState({});

    const table = useReactTable({
        data: panelsList?.data || [],  // Use empty array if panelsList is not yet available
        columns,
        state: {
            globalFilter,
            rowSelection,
        },
        onGlobalFilterChange: setGlobalFilter,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: 'auto',
        enableRowSelection: true,
        enableMultiRowSelection: false,
        getRowId: (row) => row.id,
    });

    // Handle row selection effect
    useEffect(() => {
        const selectedRows = table.getSelectedRowModel().rows;
        if (selectedRows.length > 0) {
            onPanelSelect(selectedRows[0].original);
        } else {
            onPanelSelect(null);
        }
    }, [rowSelection, onPanelSelect, table]);

    // Display loading spinner while fetching panelsList
    if (isLoading) {
        return <CenterLoadingContainer />;
    }

    return (
        <Container>
            <Row className="mt-4">
                <Col>
                    <div className="mb-2">
                        <TableFilter filterValue={globalFilter} setFilter={setGlobalFilter} />
                    </div>

                    <Table bordered hover variant="info">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id}>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    key={row.id}
                                    onClick={() => row.toggleSelected()}
                                    style={{
                                        backgroundColor: row.getIsSelected() ? 'lightblue' : 'white',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} style={{ backgroundColor: 'inherit', cursor: 'pointer' }}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default PanelsList;
