import React, { useState } from 'react';
import { Table, Modal, Container, Row, Col, Alert, Badge, Button } from 'react-bootstrap';

const ProcessDialog = ({ show, onHide, infoMessage }) => {
    const initialData = [
        {
            id: 1,
            firstName: 'Issac',
            lastName: 'Liao',
            email: 'issac@yahoo.com',
            status: {
                panel1: { name: 'Panel 1', status: 'pending' },
                panel2: { name: 'Panel 2', status: 'success' },
                panel3: { name: 'Panel 3', status: 'error' }
            }
        },
        {
            id: 2,
            firstName: 'Yu-Jui',
            lastName: 'Chang',
            email: 'abc@test.com',
            status: {
                panel1: { name: 'Panel 1', status: 'success' },
                panel2: { name: 'Panel 2', status: 'success' }
            }
        },
        {
            id: 3,
            firstName: 'Tester',
            lastName: 'Chen',
            email: 'test@ttt.com',
            status: {
                panel1: { name: 'Panel 1', status: 'error' },
                panel2: { name: 'Panel 2', status: 'pending' }
            }
        },
        {
            id: 4,
            firstName: 'Alex',
            lastName: 'Johnson',
            email: 'alex@abc.com',
            status: {
                panel1: { name: 'Panel 1', status: 'pending' }
            }
        },
        {
            id: 5,
            firstName: 'Sophia',
            lastName: 'Williams',
            email: 'sophia@xyz.com',
            status: {
                panel1: { name: 'Panel 1', status: 'success' },
                panel2: { name: 'Panel 2', status: 'pending' },
                panel3: { name: 'Panel 3', status: 'pending' }
            }
        },
    ];

    const [data, setData] = useState(initialData);

    const getStatusBadge = (status) => {
        switch (status) {
            case 'pending':
                return <Badge bg="warning" text="dark">Pending</Badge>;
            case 'success':
                return <Badge bg="success">Success</Badge>;
            case 'error':
                return <Badge bg="danger">Error</Badge>;
            default:
                return <Badge bg="secondary">Unknown</Badge>;
        }
    };

    const handleRemoveSuccess = () => {
        const updatedData = data
            .map((user) => {
                const updatedStatus = Object.fromEntries(
                    Object.entries(user.status).filter(
                        ([, panelStatus]) => panelStatus.status !== 'success'
                    )
                );
                return Object.keys(updatedStatus).length > 0 ? { ...user, status: updatedStatus } : null;
            })
            .filter(user => user !== null);
        setData(updatedData);
    };

    const handleResendInvitations = () => {
        console.log('Resend Invitations clicked');
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="process-dialog" centered>
            <Modal.Header closeButton>
                <Modal.Title id="process-dialog">Process Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            {infoMessage && (
                                <Alert variant="info">
                                    {infoMessage}
                                </Alert>
                            )}

                            <Table bordered className="align-middle" variant="info">
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {data.length > 0 ? (
                                        data.map((user) => (
                                            <tr key={user.id}>
                                                <td style={{ backgroundColor: 'white' }}>{user.firstName}</td>
                                                <td style={{ backgroundColor: 'white' }}>{user.lastName}</td>
                                                <td style={{ backgroundColor: 'white' }}>{user.email}</td>
                                                <td style={{ backgroundColor: 'white' }}>
                                                    <ul className="list-unstyled">
                                                        {Object.values(user.status).map(({ name, status }, index) => (
                                                            <li key={index} className="mb-1">
                                                                <strong>{name}:</strong> {getStatusBadge(status)}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" className="text-center">
                                                No users to process
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col className="d-flex justify-content-end">
                            <Button variant="danger" className="me-2" onClick={handleRemoveSuccess}>
                                Remove Success
                            </Button>
                            <Button variant="primary" onClick={handleResendInvitations}>
                                Resend Invitations
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ProcessDialog;
