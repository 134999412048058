import React, { useState } from 'react';
import { Container, Row, Col, Accordion, Button } from 'react-bootstrap';
import PanelsList from './lists/PanelsList';
import UsersList from './lists/UsersList';
import SelectedPanels from './lists/SelectedPanels';
import ProcessDialog from './dialogs/ProcessDialog';
import { useAddNewStore } from '../../store/add_new_store';
import './AddNewPage.css'

const AddNewPage = () => {
    const [showProcessDialog, setShowProcessDialog] = useState(false);
    const { selectedPanels, users } = useAddNewStore();

    const handleShowProcessDialog = () => setShowProcessDialog(true);
    const handleHideProcessDialog = () => setShowProcessDialog(false);

    const handleInvite = () => {
        handleShowProcessDialog();
    };

    return (
        <Container fluid>
            <Row className="mt-3">
                <Col md={12}>
                    <Accordion defaultActiveKey="0" className="mb-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                Selected ({selectedPanels.length}) Panels and Invite ({users.length}) Users
                            </Accordion.Header>
                            <Accordion.Body>

                                <Row>
                                    <Col md={6}>
                                        <SelectedPanels />
                                    </Col>
                                    <Col md={6}>
                                        <UsersList />
                                    </Col>
                                </Row>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>

            {/* Invite 和 Show Process 按鈕 */}
            <Row className="mb-3">
                <Col className="text-end">
                    <Button variant="primary" onClick={handleInvite} className="me-3">
                        Invite
                    </Button>
                    <Button variant="primary" onClick={handleShowProcessDialog}>
                        Show Process
                    </Button>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col md={12}>
                    <PanelsList />
                </Col>
            </Row>

            <ProcessDialog
                show={showProcessDialog}
                onHide={handleHideProcessDialog}
                infoMessage="Processing selected users and panels."
            />
        </Container>
    );
};

export default AddNewPage;
