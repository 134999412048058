import { create } from 'zustand';

export const useAddNewStore = create((set) => ({
    users: [],
    setUsers: (users) => set(() => ({ users })),

    selectedPanels: [],
    setSelectedPanels: (panels) => set(() => ({ selectedPanels: panels })),
    resetSelectedPanels: () => set(() => ({ selectedPanels: [] })),

    selectedPanelsRow: {},
    setSelectedPanelsRow: (rows) => set(() => ({ selectedPanelsRow: rows })),
    resetSelectedPanelsRow: () => set(() => ({ selectedPanelsRow: {} })),
}));
