import React, { useState, useMemo } from 'react';
import { useReactTable, getCoreRowModel, getFilteredRowModel, flexRender } from '@tanstack/react-table';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useAddNewStore } from '../../../store/add_new_store';
import ConfirmationAlert from '../../../components/ConfirmationAlert';
import TableFilter from '../../../components/TableFilter';
import { FaTrashAlt } from 'react-icons/fa';

const SelectedPanels = () => {
    const { selectedPanels, setSelectedPanels, selectedPanelsRow, setSelectedPanelsRow } = useAddNewStore();
    const [showConfirm, setShowConfirm] = useState(false);
    const [panelToRemove, setPanelToRemove] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');

    const columns = useMemo(() => [
        { accessorKey: 'name', header: 'Panel Name' },
        { accessorKey: 'location', header: 'Location' },
        {
            id: 'actions',
            header: '',
            cell: ({ row }) => (
                <Button
                    variant="link"
                    onClick={() => handleRemoveClick(row.original.id)}
                    style={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <FaTrashAlt style={{ color: 'red', margin: 0, padding: 0, fontSize: '16px' }} />
                </Button>
            ),
        },
    ], []);

    const table = useReactTable({
        data: selectedPanels,
        columns,
        state: { globalFilter },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: 'auto',
    });

    const handleRemoveClick = (id) => {
        setPanelToRemove(id);
        setShowConfirm(true);
    };

    const confirmRemoveSelected = () => {
        const updatedPanels = selectedPanels.filter(panel => panel.id !== panelToRemove);
        setSelectedPanels(updatedPanels);

        const updatedPanelsRow = { ...selectedPanelsRow };
        delete updatedPanelsRow[panelToRemove];
        setSelectedPanelsRow(updatedPanelsRow);

        setPanelToRemove(null);
        setShowConfirm(false);
    };

    const cancelRemoveSelected = () => {
        setPanelToRemove(null);
        setShowConfirm(false);
    };

    return (
        <>
            <Row className="mb-2">
                <TableFilter filterValue={globalFilter} setFilter={setGlobalFilter} />
            </Row>

            <Table bordered variant="info">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} >
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.length > 0 ? (
                        table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}
                                        className="align-middle"
                                        style={{ backgroundColor: 'white', }}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" className="text-center align-middle" style={{ backgroundColor: 'white' }}>
                                No panels selected
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <ConfirmationAlert
                text="Are you sure you want to remove the selected panel?"
                onConfirm={confirmRemoveSelected}
                onCancel={cancelRemoveSelected}
                show={showConfirm}
                onHide={() => setShowConfirm(false)}
            />
        </>
    );
};

export default SelectedPanels;
