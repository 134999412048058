import React, { useEffect } from 'react';
import {
	Dialog,
	TranslatedString
} from '@bosch/bt-ui';

import './LogOut.css';
import withErrorHandler from './ErrorHandler';

import { getKeycloak } from '../components/KeycloakUtil';
import CenterLoadingContainer from '../components/CenterLoadingContainer';

const baseRouter = new URL(MOBY_API_URL).pathname;

function logOutOfKeycloak() {
	const keycloak = getKeycloak();
	keycloak.init({ checkLoginIframe: false }).then(() => {
		// When keycloak logout success, redirect to /logout path to delete cookie after
		keycloak.logout({ redirectUri: window.location.origin + '/logout' });
	});
}

const LogOut = () => {
	useEffect(() => {
		console.log('LogOut:logOutOfKeycloak', window.location.origin + '/logout');
		// Do not try to delete cookie here, it will trigger <Page.jsx> re-render
		logOutOfKeycloak();
	}, []);

	return (
		<Dialog
			htmlAttributes={{
				className: 'log-out-modal'
			}}
			focusTrapped={false}
			title={<TranslatedString stringId="dialog-info" />}
			// Can be either "error", "warning", "success", "info", or "none"
			alertType="info"
		>
			<h1 className="font-large">
				<TranslatedString stringId="logout-keycloak" />
			</h1>

			<CenterLoadingContainer />
		</Dialog>
	);
}

export default withErrorHandler(LogOut);
