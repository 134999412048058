import React, { useMemo, useState, useEffect, useCallback, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Stack from 'react-bootstrap/Stack';
import { useQuery } from '@tanstack/react-query';

import './ProfileAccess.css';
import LogoutIcon from '../../icons/logout.svg';
import UserIcon from '../../icons/my-brand-frame.svg';
import CloseIcon from '../../icons/close.svg';

import {
    Icon,
    IntegratedButton,
    Popover,
    usePopover,
    Link
} from '@bosch/bt-ui';

const loginLinkDefaultAttributes = {
    hasIconOnLeft: true,
    linkType: 'button',
    buttonType: 'primary',
    size: 'small'
};

const logoutLinkDefaultAttributes = {};

const profileLinkDefaultAttributes = {};

const fetchCompanyData = async (token) => {
    const response = await fetch(MOBY_API_URL + '/api/companymanager/v1/companies/user1', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export const ProfileAccess = React.memo((props) => {
    const {
        isLoggedIn,
        dropDownHeadline,
        user,
        appHeadline,
        appLinks,
        logoutLink,
        profileLink,
        loginLink,
        shouldShowCloseButton = false,
        token
    } = props;

    const {
        shouldShowPopover,
        showPopover,
        hidePopover
    } = usePopover();

    const [popoverParentElement, setPopoverParentElement] = useState();

    const {
        text: loginLinkContent,
        ...loginLinkAttributes
    } = { ...loginLinkDefaultAttributes, ...loginLink };

    const {
        text: logoutLinkContent,
        ...logoutLinkAttributes
    } = { ...logoutLinkDefaultAttributes, ...logoutLink };

    const {
        text: profileLinkContent,
        href: profileLinkHref,
        ...profileLinkAttributes
    } = { ...profileLinkDefaultAttributes, ...profileLink };

    const { data: companyData, isLoading } = useQuery({
        queryKey: ['companyData'],
        queryFn: () => fetchCompanyData(token),
    });

    return (
        <div
            className={classNames(
                'M-ProfileAccess',
                shouldShowPopover && 'is-active'
            )}
        >
            {
                isLoggedIn && <Stack direction="horizontal"
                    className={classNames(
                        'M-ProfileAccess__toggleLink',
                        isLoggedIn && 'is-loggedIn',
                    )} gap={3}
                >
                    <DropdownButton
                        id="dropdown-basic-button"
                        title={isLoading ? 'Loading...' : companyData?.data[0]?.companyName || 'Company Name'}
                        disabled={true}
                        variant='outline'
                    >
                    </DropdownButton>
                </Stack>
            }
            <IntegratedButton
                className={classNames(
                    'M-ProfileAccess__toggleLink',
                    shouldShowPopover && 'is-active',
                    isLoggedIn && 'is-loggedIn'
                )}
                onClick={showPopover}
            >
                <Icon icon={UserIcon} ref={setPopoverParentElement} />

                {
                    isLoggedIn && user.loggedInUsername != null &&
                    <span
                        ref={setPopoverParentElement}
                        className="M-ProfileAccess__userNameButton"
                    >
                        {user.loggedInUsername}
                    </span>
                }
            </IntegratedButton>

            {
                shouldShowPopover &&
                <Popover
                    hidePopover={hidePopover}
                    parentElement={popoverParentElement}
                >
                    {
                        (dropDownHeadline != null || shouldShowCloseButton) &&
                        <div className="M-ProfileAccess__dropDownHeader">
                            {
                                dropDownHeadline != null &&
                                <div className="M-ProfileAccess__dropDownHeadline">{dropDownHeadline}</div>
                            }
                            {
                                shouldShowCloseButton &&
                                <a className="M-ProfileAccess__dropDownCloseBtn" onClick={hidePopover}>
                                    <Icon icon={CloseIcon} size={20} />
                                </a>
                            }
                        </div>
                    }

                    {
                        isLoggedIn ?
                            <div className="M-ProfileAccess__loggedInInfo">
                                {
                                    (user.name != null || user.monogram != null || user.email != null) &&
                                    <div className="M-ProfileAccess__userInfoContainer">
                                        {
                                            user.monogram != null &&
                                            <div className="M-ProfileAccess__userMonogram">{user.monogram}</div>
                                        }
                                        <div>
                                            {
                                                // It's fairly common for Chinese names to only be two characters.
                                                // In this case, the monogram and the user's name are the same,
                                                // and it looks strange to have the same name displayed twice,
                                                // so we just show the monogram and not the user's name.
                                                user.name !== user.monogram &&
                                                <div className="M-ProfileAccess__userName">{user.name}</div>
                                            }
                                            <div className="M-ProfileAccess__userEmail">{user.email}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    appLinks &&
                                    <div className="M-ProfileAccess__linksContainer">
                                        <div className="M-ProfileAccess__appHeadline">{appHeadline}</div>
                                        {
                                            appLinks.map(link => {
                                                const {
                                                    key: linkKey,
                                                    text: linkContent,
                                                    ...linkAttributes
                                                } = link;

                                                return (
                                                    <Link key={linkKey} {...linkAttributes}>
                                                        {linkContent}
                                                    </Link>
                                                );
                                            })
                                        }
                                    </div>
                                }
                                <div className="M-ProfileAccess__logoutBtnContainer">
                                    {profileLink != null && <IntegratedButton {...profileLinkAttributes}>
                                        <Icon icon={UserIcon} />

                                        {profileLinkContent}
                                    </IntegratedButton>}
                                    <IntegratedButton {...logoutLinkAttributes}>
                                        <Icon icon={LogoutIcon} />

                                        {logoutLinkContent}
                                    </IntegratedButton>
                                </div>
                            </div>
                            :
                            <div className="M-ProfileAccess__loginBtn">
                                <Link {...loginLinkAttributes}>
                                    {loginLinkContent}
                                </Link>
                            </div>
                    }
                </Popover>
            }
        </div>
    );
});

ProfileAccess.displayName = 'ProfileAccess';
ProfileAccess.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    profileLink: PropTypes.object,
    dropDownHeadline: PropTypes.node,
    user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.node,
        monogram: PropTypes.string,
        loggedInUsername: PropTypes.node
    }),
    appHeadline: PropTypes.node,
    appLinks: PropTypes.arrayOf(PropTypes.object),
    logoutLink: PropTypes.object,
    shouldShowCloseButton: PropTypes.bool
};