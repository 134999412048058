import { create } from 'zustand';

export const useExistingStore = create((set) => ({
    selectedUsers: [],
    setSelectedUsers: (users) => set(() => ({ selectedUsers: users })),
    resetSelectedUsers: () => set(() => ({ selectedUsers: [] })),

    selectedPanels: [],
    setSelectedPanels: (panels) => set(() => ({ selectedPanels: panels })),
    resetSelectedPanels: () => set(() => ({ selectedPanels: [] }))
}))