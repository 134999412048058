import React, { useMemo, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Table, Modal, Container, Row, Col, Alert, Button } from 'react-bootstrap';

const ProcessDialog = ({ show, onHide, infoMessage, mode }) => {
    const initialUserMockData = useMemo(() => [
        { id: 1, firstName: 'Issac', lastName: 'Liao', email: 'issac@yahoo.com', status: 'pending' },
        { id: 2, firstName: 'Yu-Jui', lastName: 'Chang', email: 'abc@test.com', status: 'success' },
        { id: 3, firstName: 'Tester', lastName: 'Chen', email: 'test@ttt.com', status: 'error' },
        { id: 4, firstName: 'Alex', lastName: 'Johnson', email: 'alex@abc.com', status: 'pending' },
        { id: 5, firstName: 'Sophia', lastName: 'Williams', email: 'sophia@xyz.com', status: 'success' },
    ], []);

    const initialPanelMockData = useMemo(() => [
        { id: 1, name: 'Panel A', location: 'Location 1', status: 'pending' },
        { id: 2, name: 'Panel B', location: 'Location 2', status: 'success' },
        { id: 3, name: 'Panel C', location: 'Location 3', status: 'error' },
        { id: 4, name: 'Panel D', location: 'Location 4', status: 'pending' },
    ], []);

    const [userMockData, setUserMockData] = useState(initialUserMockData);
    const [panelMockData, setPanelMockData] = useState(initialPanelMockData);

    const renderStatus = (status) => {
        if (status === 'pending') return <span className="text-warning">Pending</span>;
        if (status === 'success') return <span className="text-success">Success</span>;
        if (status === 'error') return <span className="text-danger">Error</span>;
        return <span>{status}</span>;
    };

    const userColumns = useMemo(() => [
        { accessorKey: 'firstName', header: 'First Name' },
        { accessorKey: 'lastName', header: 'Last Name' },
        { accessorKey: 'email', header: 'Email' },
        {
            id: 'status',
            header: 'Status',
            cell: ({ row }) => renderStatus(row.original.status),
        },
    ], []);

    const panelColumns = useMemo(() => [
        { accessorKey: 'name', header: 'Name' },
        { accessorKey: 'location', header: 'Location' },
        {
            id: 'status',
            header: 'Status',
            cell: ({ row }) => renderStatus(row.original.status),
        },
    ], []);

    const data = useMemo(() => (mode === 'users' ? userMockData : panelMockData), [mode, userMockData, panelMockData]);
    const columns = useMemo(() => (mode === 'users' ? userColumns : panelColumns), [mode, userColumns, panelColumns]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const handleRemoveSuccess = () => {
        if (mode === 'users') {
            const updatedUsers = userMockData.filter(user => user.status !== 'success');
            setUserMockData(updatedUsers);
        } else if (mode === 'panels') {
            const updatedPanels = panelMockData.filter(panel => panel.status !== 'success');
            setPanelMockData(updatedPanels);
        }
    };

    const handleResendInvitations = () => {
        console.log('Resend Invitations clicked');
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="process-dialog" centered>
            <Modal.Header closeButton>
                <Modal.Title id="process-dialog">Process Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            {infoMessage && (
                                <Alert variant="info">
                                    {infoMessage}
                                </Alert>
                            )}

                            <Table bordered variant="info" className="align-middle">
                                <thead>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th key={header.id}>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody className="bg-white">
                                    {table.getRowModel().rows.length > 0 ? (
                                        table.getRowModel().rows.map(row => (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map(cell => (
                                                    <td key={cell.id} style={{ backgroundColor: 'white' }}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={mode === 'users' ? 4 : 3} className="text-center">
                                                {mode === 'users' ? 'No users to process' : 'No panels to process'}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col className="d-flex justify-content-end">
                            <Button variant="danger" className="me-2" onClick={handleRemoveSuccess}>
                                Remove Success
                            </Button>
                            <Button variant="primary" onClick={handleResendInvitations}>
                                Resend Invitations
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ProcessDialog;
