import React, { useEffect } from 'react';
import {
	Dialog,
	TranslatedString
} from '@bosch/bt-ui';

import { Navigate, useLocation } from 'react-router-dom';
import { getKeycloak } from '../components/KeycloakUtil';
import CenterLoadingContainer from '../components/CenterLoadingContainer';
import { prepareLogData } from '../components/Util';

function getReturnUrl(queryString) {
	const queryParams = new URLSearchParams(queryString);

	const returnUrl = queryParams.get('return-url');

	if (returnUrl === '' || returnUrl === '/login' || returnUrl === '/logout')
		return null;

	return returnUrl;
}

const LoginPage = (props) => {
	const {
		token,
		setToken
	} = props;
	console.log('<LoginPage>');

	const location = useLocation();
	const keycloak = getKeycloak();

	useEffect(() => {
		if (!keycloak.didInitialize) {
			keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then(authenticated => {
				keycloak.loadUserInfo().then(userInfo => {
					// var displayName = userInfo.preferred_username.toUpperCase()
					// displayName = displayName.replace(userInfo.family_name.toUpperCase() + ' ' + userInfo.given_name.toUpperCase(), userInfo.family_name + ' ' + userInfo.given_name)

					var displayName = userInfo.family_name + ' ' + userInfo.given_name
					const user = { name: userInfo.name, email: userInfo.email, info: displayName }

					console.log('LoginPage -> setToken');
					const data = prepareLogData(keycloak.token, 'HomePage', 'UserLogin');

					// fetch(MOBY_API_URL + '/api/activity/v1/activitylog', {
					// 	method: 'POST',
					// 	headers: {
					// 			'Content-Type': 'application/json',
					// 			'Authorization': 'Bearer ' + keycloak.token
					// 			},
					// 	body: JSON.stringify(data)
					// })
					//Add activity log
					setToken({ token: keycloak.token, authenticated: authenticated, user: user, exp: keycloak.tokenParsed.exp })
				});
			})

		}
	}, [token.token, keycloak, setToken]);

	const returnUrl = getReturnUrl(location.search);

	console.log('returnUrl:' + returnUrl + '/' + token.authenticated);

	useEffect(() => {
		console.log(returnUrl);
	}, [returnUrl]);

	if (token.token) {
		if (!token.authenticated) {
			return (<div>Unable to authenticate!</div>)
		}
		else {
			return <Navigate to={returnUrl || '/'} replace />
		}
	}
	else {
		return (
			<Dialog
				htmlAttributes={{
					className: 'log-in-modal'
				}}
				focusTrapped={false}
				title={<TranslatedString stringId="dialog-info" />}
				// Can be either "error", "warning", "success", "info", or "none"
				alertType="info"
			>
				<h1 className="font-large">
					<TranslatedString stringId="login-keycloak" />
				</h1>

				<CenterLoadingContainer />
			</Dialog>
		);
	}
};

export default LoginPage;